<template>
  <div id="comment-form">
    <UForm :schema="schema" :state="commentForm.data" @submit="handleSubmit" class="space-y-3">
      <FormGroup label="&nbsp;">
        <UInput ref="input" v-model="commentForm.data.text" size="lg" :ui="inputStyles" variant="none"
          placeholder="Write a comment..." :keyWords="['@russ']" @input="handleInput">
          <template #leading>
            <UPopover :popper="{ arrow: true, placement: 'bottom-start' }" :ui="popperStyles" overlay>
              <font-awesome-icon icon="fa-light fa-face-smile"
                class="text-lg text-pink-600 dark:text-gray-100/75 rounded-full p-2 mt-[6px] hover:bg-cool-200 hover:dark:bg-[#2B3236]" />
              <template #panel>
                <div class="flex flex-row items-center gap-1 p-4 text-lg">
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer py-1 px-2"
                    @click="handleEmojiSelect('❤️')">❤️</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer py-1 px-2"
                    @click="handleEmojiSelect('👍')">👍</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer py-1 px-2"
                    @click="handleEmojiSelect('😘')">😘</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer py-1 px-2"
                    @click="handleEmojiSelect('🤯')">🤯</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer py-1 px-2"
                    @click="handleEmojiSelect('🥵')">🥵</a>
                </div>
              </template>
            </UPopover>
          </template>
          <template #trailing>
            <Button theme="secondary" :iconOnly="true" size="sm" @click="handleSubmit">
              <font-awesome-icon icon="fa-solid fa-location-arrow-up" class="fa-rotate-90" size="md" />
            </Button>
          </template>
        </UInput>
      </FormGroup>
    </UForm>
  </div>
</template>

<script setup>
import { object, string, number } from 'yup'

const props = defineProps({
  postId: {
    type: String,
    required: true
  }
})

const emit = defineEmits([
  "comment-created"
])

const userStore = useUserStore()
const config = useRuntimeConfig()
const toast = useToast()
const input = ref(null)

const commentForm = reactive({
  data: {
    text: '',
    postId: props.postId
  }
})

const schema = object({
  text: string().required().max(140)
})

const handleInput = (value) => {
  if (commentForm.data.text && commentForm.data.text.includes('@')) {
    // TODO: Implement mention suggestions
    console.log('Caught possible mention', commentForm.data.text.match(/\B@\w+/g))
  }
}

const handleSubmit = async () => {
  if (!commentForm.data.text.length) {
    toast.add({ title: 'Comment cannot be empty', type: 'error' })
    return
  }

  if (userStore.loggedIn) {
    try {
      await $api(
        config.public.API_URL + `/api/posts/${props.postId}/comments`,
        {
          method: 'POST',
          body: {
            comment: {
              postId: props.postId,
              text: commentForm.data.text
            }
          }
        }
      )

      commentForm.data.text = ''
      emit('comment-created')
    } catch (err) {
      toast.add({ title: useErrorMessage(err.response._data), type: 'error' })
    }
  } else {
    await navigateTo('/join')
  }
  console.log('Comment submitted')
}

const handleEmojiSelect = (emoji) => {
  commentForm.data.text = commentForm.data.text + emoji
  input.value.input.focus()
  input.value.input.select()
}

const popperStyles = {
  overlay: {
    base: 'fixed inset-0 transition-opacity z-50',
    background: 'bg-gray-200/25 dark:bg-gray-800/25',
    transition: {
      enterActiveClass: 'ease-out duration-200',
      enterFromClass: 'opacity-0',
      enterToClass: 'opacity-100',
      leaveActiveClass: 'ease-in duration-150',
      leaveFromClass: 'opacity-100',
      leaveToClass: 'opacity-0',
    },
  },
}

const inputStyles = {
  variant: {
    none: 'font-semibold ' +
      'focus:ring-1 focus:ring-[#f26998] ' +
      'dark:ring-[#384247] dark:bg-[#1b2022] ' +
      'dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]'
  },
  icon: {
    leading: {
      pointer: '',
      padding: {
        lg: 'px-1'
      }
    },
    trailing: {
      pointer: '',
      padding: {
        lg: 'px-0'
      }
    }
  }
}
</script>
